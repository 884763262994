.app-container {
	background-color: #ffffff;
	border-radius: 5px;
	width: 70%;
	margin: 5rem auto;
	display: flex;
	flex-direction: column;
	padding-left: 3em;
	padding-right: 3em;
}

.app-container h1 {
	margin: 0;
}

.no-results {
	font-weight: 700;
	color: var(--warning-color)
}


