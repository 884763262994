.side-menu {
    padding: 0;
    display: flex;
    gap: 24px;
    width: 15rem;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    -moz-box-shadow:rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.side-menu ul {
    display: flex;
    width: 15rem;
    display: flex;
    flex-direction: column;  
    margin: 0;
    padding: 0;
}

.side-menu a {
    text-decoration: none;
    display: block;
    position: relative;
    line-height: 2;
    padding: 15px 60px 12px 10px;
    color: var(--text-dark);
    padding-left: 1rem;
    font-size: 0.95em;
    border-bottom: 1px solid #c2c8d1;
}

.side-menu a.active {
    background-color: var(--deep-blue);
    color: var(--white);
}

.side-menu a:hover, .side-menu a:focus {
    background-color: var(--background-color);
    color: var(--text-dark);
}

.side-menu a.active:hover, .side-menu a.active:focus {
    background-color: var(--deep-blue);
    color: var(--white);
}

.side-menu a:before {
    content: "";
}

.side-menu a:before {
    position: absolute;
    height: 5px;
    background: var(--cyan);
    left: 0;
    bottom: 0;
    width: 0;
    transition: width 0.3s;
}

.side-menu a:focus::before, .side-menu a:hover::before {
    width: 100%;
}

svg.menu-arrow {
    fill: var(--cyan);
    position: absolute;
    right: 15px;
    top: 22px;
    margin: auto 0;
}
