.tabs-root {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.tabs-list {
    flex-shrink: 0;
    display: flex;
    width: 6rem;
    gap: 8px;
}

.tabs-trigger {
    font-family: inherit;
    border: none;
    background-color: white;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85em;
    line-height: 1;
    color: var(--text-mid);
    user-select: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #F4F4F4;
    cursor: pointer;
}

.tabs-trigger:hover {
    color: var(--primary-color);
}

.tabs-trigger[data-state='active'] {
    font-weight: 700;
    background-color: var(--deep-blue);
    color: var(--white);
}

.tabs-trigger:focus {
    position: relative;
    border: 1px solid var(--primary-color);
    border-bottom: none;
}

@media (min-width: 1366px) {
	.tabs-trigger {
        font-size: 1em;;
    }
}