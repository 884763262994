button.icas-button.month-picker {
    padding: 0 1rem;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: var(--background-color);

}

.react-datepicker__month-text--keyboard-selected:hover {
    background-color: var(--primary-color);
    color: var(--white);
}

.react-datepicker__month .react-datepicker__month-text {
    padding: 0.25rem;
}

.react-datepicker__month-container {
    font-family: 'Martel Sans';
}

.react-datepicker__header {
    background-color: var(--cyan);
}

.react-datepicker__navigation-icon::before {
    border-color: var(--deep-blue);
    top: 11px;
}

.react-datepicker__navigation-icon::before:hover {
    border-color: var(--text-dark);
}