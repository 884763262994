.table-container {
	border: 1px solid var(--deep-blue);
	border-radius: 6px;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	overflow-x: auto;
}

.app-container.with-tabs .table-container.first-tab-child,
#student-view .table-container.first-tab-child,
.app-container.with-tabs .table-container.first-tab-child thead,
#student-view .table-container.first-tab-child thead {
	border-top-left-radius: 0;
}

.table-container * {
	box-sizing: border-box;
}

table {
	border-collapse: collapse;
	font-size: 0.85em;
}

thead {
	-webkit-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: var(--deep-blue);
	color: #fff;
	margin: 0;
	padding: 1%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-size: 1em;
}

thead tr {
	-webkit-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-inline-flex;
	display: inline-flex;
	text-align: center;
}

thead th {
	flex: 100 0 auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 5px;
	line-height: normal;
	position: relative;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	box-shadow: inset 0 0 0 0 transparent;
}

thead th.sub-column {
	width: 120px;
}

thead th:first-of-type {
	border-left: none;
}

tbody {
	font-size: 0.95em;
	min-width: 800px;
	-webkit-flex: 99999 1 auto;
	flex: 99999 1 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
}

tbody tr {
	border-bottom: 1px solid #a9a9a9;
	-webkit-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
}

tbody tr.empty-row {
	padding-top: 0.9rem;
}

tbody tr:last-of-type {
	border-bottom: none;
}

tbody tr:nth-child(odd) {
	background: rgba(0, 0, 0, 0.03);
}

.app-container tbody tr:hover {
	background: var(--primary-color);
	color: var(--white);
	cursor: pointer;
}

tbody td {
	flex: 100 0 auto;
	width: 120px;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 7px 5px;
	overflow: hidden;
	transition: 0.3s ease;
	transition-property: width, min-width, padding, opacity;
	text-align: center;
	padding: 16px;
}

tfoot {
	color: gray;
}

tfoot th {
	font-weight: normal;
}

.pagination-container {
	align-items: center;
	gap: 16px;
}

.pagination-container,
.filter-container {
	display: flex;
	padding: 16px 0;
}

.filter-container {
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	gap: 24px;
}

.filter-container.large {
	flex-direction: column;
	gap: 24px;
	align-items: start;
}

@media (min-width: 1366px) {
	.filter-container.large {
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}
}

.filter-container div.icas-textfield-wrapper {
	margin-bottom: 0;
}

.table-count {
	display: flex;
	align-items: center;
	gap: 8px;
}

.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: var(--white);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
}

table th:last-of-type .resizer {
	display: none;
}
