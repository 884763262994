:root {
    --primary-color: #4d46d9;
    --white: #ffffff;
    --deep-blue: #1c2533;
    --steel-grey: #8493a8;
    --cyan: #22b9da;
    --orange: #f88d2a;
    --text-dark: #282828;
    --text-mid: #585858;
    --warning-color: #bb1826;
    --background-color: #edecfb;
    --success-color: #00774a;
    font-family: 'Martel Sans', Arial, Helvetica, sans-serif;
    color: var(--text-dark);
}

main {
    padding-bottom: 0;
}

div.content-container {
    display: flex;
}

div.app-container {
    margin: 3em;
    width: 100%;
    display: flex;
    gap: 1em;
}

div.app-container > * {
    margin: 0;
}

div.app-container h2, div.app-container p {
    margin: 0;
}

h2.with-line:after, h2.modal_label:after {
    background-color: var(--cyan);
    content: "";
    display: block;
    height: 5px;
    position: relative;
    width: 100px;
    margin-bottom: 0.5em;
}

div.app-container > *:first-child {
    margin-top: 1em;
}

div.app-container > *:last-child {
    margin-bottom: 1em;
}


h2 {
    line-height: 2em;
}


@media (min-width: 992px) {
    div.app-container {
        max-width: 65vw;
    }
}


@media (min-width: 1366px) {
    div.app-container {
        max-width: 75vw;
    }
}