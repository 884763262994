button.icas-button.role-header {
    width: 15rem;
    align-items: center;
    flex-direction: row;
    height: 3rem;
    overflow: hidden;
    justify-content: flex-start;
}

.role-header svg {
    color: var(--cyan);
    margin-left: 1rem;
}

.role-header:hover svg {
    color: var(--primary-color)
}

div#title-container {
    display: none;
}

#layout-container h1.app-title {
    color: #4d46d9;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.75em;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: calc(15.5% - 0.8em);
    margin-right: auto;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    width: 70%;
}

#layout-container h1.app-title.with-role {
    margin-left: 0.5rem;
}

@media (min-width: 768px) {
    div#title-container {
        align-items: center;
        background-color: #ffffff;
        display: inline-flex;
        padding-top: 0;
    }

    h1.app-title {
        margin-bottom: 0em;
        margin-left: calc(15.5% - 1.5em);
    }
}

@media (min-width: 992px) {
    h1.app-title {
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;
    }

}
