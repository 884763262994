div#student-view {
	width: 90%;
	gap: 0;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    top: 1rem;
	border-radius: 0;
}

#student-view .tabs-trigger[data-state='active'] {
	background-color: var(--background-color);
	color: var(--text-dark);
}

.student-view-container {
	background-color: var(--background-color);
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-radius: 5px;
	border-top-left-radius: 0;
}

.student-view-container .table-container,
.student-view-container .filter-container {
	width: 95%;
	margin: auto;
}

#student-view .student-view-container .tabs-root {
    width: 95%;
    margin: auto;
}

.student-view-container.with-tabs .table-container {
    width: 100%;
}

#student-view .student-view-container .tabs-trigger[data-state='active'] {
	background-color: var(--deep-blue);
	color: var(--white);
}

