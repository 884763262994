div#role-picker {
    width: 60%;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    top: 1rem;
}

div#role-picker #header_close_modal {
    display: none;
}

.roles-container {
    padding: 3%;
    cursor: pointer;
    margin: 5%;
    border: 0.15em solid var(--text-mid);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.roles-info {
    display: flex;
    gap: 16px;
}

.roles-container > .roles-info > div > * {
    margin: 0;
    padding: 0;
}

.roles-container h3 {
    line-height: 1.75em;
}

.roles-container h4 {
    line-height: 1.75em;
}

.roles-container h5 {
    line-height: 1.75em;
}

.roles-button {
    width: 7em;
    align-self: flex-end;
}

.roles-container svg {
    color: var(--primary-color)
}